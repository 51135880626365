import React from 'react';

import SectionArrayText from './SectionArrayText.js';

const CustomListingFieldsReviews = props => {
  const { publicData, intl } = props;

  const { reviews = [] } = publicData;

  const reviewsFormatted = reviews?.map(r => {
    return {
      title: r.fullName,
      value: r.review
    }
  });

  return (
    <>
      <SectionArrayText
        title={intl.formatMessage({ id: "ListingPage.referencesAndReviews" })}
        options={reviewsFormatted}
      />
    </>
  );
};

export default CustomListingFieldsReviews;
